import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUI } from "../../components/UIContext";
// import { set } from "react-hook-form";
import { SINGLE_MERCHANT } from "../../gql/merchant";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useStateContext } from "../../contexts/ContextProvider";


const DetailMerchant = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { currentColor } = useStateContext();
    const {setDeleteAction, deleteAction} = useUI();

    console.log("action",deleteAction)

    const { data, error } = useQuery(SINGLE_MERCHANT, {
        variables: { id: id }, fetchPolicy: "network-only"
    });

    console.log("data", data)

    if (!data) {
        return "Loading...";
    }

    return (
        <div>
            <div className=" ml-10 mt-5">
                <button className=" px-3 py-2 rounded-sm"
                    onClick={() => navigate("/merchant")}
                    style={{ background: currentColor }}
                ><IoMdArrowRoundBack className=" text-xl text-white" />
                </button>
            </div>
            <div class="bg-white max-w-full shadow overflow-hidden sm:rounded-lg m-16">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Merchant
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Details and informations about merchant.
                    </p>
                </div>
                <div class="border-t border-gray-200">
                    {
                        <div className="grid lg:grid-cols-2 sm:grid-cols-none">
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    ID:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.merchants_by_pk.id}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Name:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.merchants_by_pk.name}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Email:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.merchants_by_pk.email ? data.merchants_by_pk.email : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Phone Number:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.merchants_by_pk.phone ? data.merchants_by_pk.phone : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Image:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.merchants_by_pk.image_url ? data.merchants_by_pk.image_url : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Otp:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.merchants_by_pk.otp ? data.merchants_by_pk.otp : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Created At:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.merchants_by_pk.created_at ? data.merchants_by_pk.created_at.slice(0, 10) : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Updated At:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.merchants_by_pk.updated_at ? data.merchants_by_pk.updated_at.slice(0, 10) : "-"}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div class="px-4 py-5 sm:px-6">
                    <span class="">
                        <button className="bg-orange-400 px-4 py-1 rounded-sm mr-6" onClick={() => navigate(`/edit_merchant/${data.merchants_by_pk.id}`)}>Edit</button>
                    </span>
                    {/* <span class="">
                        <button className="bg-rose-600 px-4 py-1 rounded-sm" 
                        onClick={() => 
                            setDeleteAction({
                                name : "MERCHANT_DELETE",
                                data : data.merchants_by_pk.id,
                            })
                        }
                        >
                            Delete
                        </button>
                    </span> */}
                </div>
            </div>
        </div>
    )
}

export default DetailMerchant;