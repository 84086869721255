import { gql } from "@apollo/client";

export const CREATE_COURIER = gql`
mutation create_courier($courier_status : String!, $fk_merchant_id : String!, $name : String!, $password : String!, $phone : String!) {
    CourierRegister(courier_status: $courier_status, fk_merchant_id: $fk_merchant_id, name: $name, password: $password, phone: $phone) {
      accessToken
      error
      expiredAt
      message
      type
    }
  }
`;

export const ALL_COURIERS = gql`
query all_couriers {
    couriers {
      courier_latitude
      courier_longitude
      courier_status
      created_at
      disabled
      fk_merchant_id
      id
      name
      password
      phone
      updated_at
    }
    couriers_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const SINGLE_COURIER = gql`
query single_courier($id : uuid!) {
    couriers_by_pk(id:$id) {
      courier_latitude
      courier_longitude
      courier_status
      created_at
      disabled
      fk_merchant_id
      id
      name
      password
      phone
      updated_at
      merchant {
        name
      }
    }
  }
  
`;

export const UPDATE_COURIER = gql`
mutation update_courier($id : uuid!, $fk_merchant_id : uuid!, $name : String!, $phone : String!, $courier_status : String!) {
    update_couriers_by_pk(pk_columns: {id: $id}, _set: {fk_merchant_id: $fk_merchant_id, name: $name, phone: $phone, courier_status: $courier_status}) {
      fk_merchant_id
      name
      phone
      courier_status
    }
  }
`;

export const ONLINE_COURIER = gql`
query online_courier{
  online_couriers {
    id
  }
}
`;