import React from "react";

import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useUI } from "./UIContext";
import { useMutation } from "@apollo/client";
import { DELETE_MERCHANT } from "../gql/merchant";

const ConfirmAlert = () => {
    const { deleteAction, setDeleteAction } = useUI();

    const id = deleteAction.data;

    const [merchant_delete] = useMutation(DELETE_MERCHANT, {
        onError: (err) => {
            alert("Error on merchant delete");
            window.location.reload();
            console.log("error",err)
        },
        onCompleted: (data) => {
            window.location.reload();
        }
    });

    const handleDelete = async () => {
        await merchant_delete({
            variables: {
                id: id,
            }
        })
    }

    return (
        <>
            <Transition appear show={deleteAction.data === null ? false : true} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() =>
                    setDeleteAction({
                        name: "MERCHANT_DELETE",
                        data: null,
                    })
                }>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        DELETE
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure want to delete?
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-6"
                                            onClick={() =>
                                                setDeleteAction({
                                                    name: "MERCHANT_DELETE",
                                                    data: null,
                                                })
                                            }
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-rose-600 px-4 py-2 text-sm font-medium text-white hover:bg-rose-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default ConfirmAlert;
