import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { set } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CREATE_MERCHANT } from "../../gql/merchant";

const CreateMerchant = () => {

    const [error, setErrors] = useState({});
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleChange = (value, field) => {

        const errorObject = { ...error };
        if (!field) {
            errorObject[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
                } is required`;
        } else {
            errorObject[field] = "";
        }

        setErrors(errorObject);

        switch (field) {
            case "name":
                setName(value);
                break;

            case "phone":
                setPhone(value);
                break;

            case "email":
                setEmail(value);
                break;

            case "password":
                setPassword(value);
                break;
        }
    };

    const [create_merchant,{loading}] = useMutation(CREATE_MERCHANT, {
        onError: (error) => {
            alert(error.message);
        },
        onCompleted: (result) => {
            setName("");
            setPhone("");
            setPassword("");
            setEmail("");
            alert("New Merchant Has Been Added");
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isError = false;
        const errorObject = {};

        if (!name) {
            isError = true;
            errorObject.name = "Name is required!";
        }
        if (!phone) {
            isError = true;
            errorObject.phone = "Phone is required!";
        }
        if (!email) {
            isError = true;
            errorObject.merchantKey = "Email is required!";
        }
        if (!password) {
            isError = true;
            errorObject.password = "Password is required!";
        }
        if (isError) {
            setErrors(errorObject);
            return;
        }

        try {
            await create_merchant({
                variables: {
                    name: name,
                    password: password,
                    phone: phone,
                    email: email,
                }
            });
        } catch (error) {
        }
        navigate(-1);
    }

    return (
        <div className="py-10 sm:py-15">
            <div className="mx-20">
                <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">Create Merchant Here</h2>
            </div>
            <form action="#" method="POST" className="mt-10 mx-20  sm:mt-15">
                <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2">
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Name"
                                autoComplete="organization"
                                value={name}
                                onChange={(e) => handleChange(e.target.value, "name")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {
                                error.name && (
                                    <span className="error text-rose-700">{error.name}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Phone
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Phone Number"
                                autoComplete="organization"
                                value={phone}
                                onChange={(e) => handleChange(e.target.value, "phone")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {
                                error.phone && (
                                    <span className="error text-rose-700">{error.phone}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter your email"
                                autoComplete="organization"
                                value={email}
                                onChange={(e) => handleChange(e.target.value, "email")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {
                                error.email && (
                                    <span className="error text-rose-700">{error.email}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Password
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Password"
                                autoComplete="organization"
                                value={password}
                                onChange={(e) => handleChange(e.target.value, "password")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {
                                error.password && (
                                    <span className="error text-rose-700">{error.password}</span>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreateMerchant;