import React from "react";

const initialState = {
    deleteAction : {
        name : "",
        data : null
    }
};

export const UIContext = React.createContext(initialState);

export const uiReducer = (state, action) => {
    switch(action.type) {
        case "SET_DELETE_ACTION" : {
            return {
                ...state,
                deleteAction : {
                    name : action.payload.name,
                    data : action.payload.data
                },
            };
        }
        default : 
        return state;
    }
}

export const UIProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(uiReducer, initialState);

    const setDeleteAction = React.useCallback(
        (payload) => dispatch({type : "SET_DELETE_ACTION", payload}),[dispatch]
    );

    const value = React.useMemo(
        () => ({
            ...state,
            setDeleteAction,
        }),
        [
            state,
            setDeleteAction,
        ]
    );

    return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUI = () => {
    const context = React.useContext(UIContext);

    if(context === undefined)
        throw new Error(`useUI must be used within a UIProvider`);

    return context;
}