import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { SINGLE_MERCHANT, UPDATE_MERCHANT } from "../../gql/merchant";

const EditMerchant = () => {

    const { id } = useParams();
    const [error, setErrors] = useState({});
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { data, errors } = useQuery(SINGLE_MERCHANT, {
        variables: { id: id }, fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (data) {
            setValues({
                id: data.merchants_by_pk.id,
                name: data.merchants_by_pk.name,
                phone: data.merchants_by_pk.phone,
                merchantKey: data.merchants_by_pk.merchant_key,
            })
        }
    }, [data]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [changeMerchant] = useMutation(UPDATE_MERCHANT, {
        onError: (err) => {
            alert("Error on Server");
            console.log("error",err)
            setLoading(false);
        },
        onCompleted: (result) => {
            setValues({});
            alert("Merchant has been updated");
            setLoading(false);
            navigate("/merchant")
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await changeMerchant({
            variables: {
                ...values,
                id: values.id,
                merchant_key: values.merchantKey,
                name: values.name,
                phone: values.phone,
            }
        })
    }

    return (
        <div className="py-10 sm:py-15">
            <div className="mx-20">
                <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">Edit Merchant Here</h2>
            </div>
            <form action="#" method="POST" className="mt-10 mx-20  sm:mt-15">
                <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2">
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Name"
                                autoComplete="organization"
                                value={values.name}
                                onChange={handleChange("name")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {/* {
                                error.name && (
                                    <span className="error text-rose-700">{error.name}</span>
                                )
                            } */}
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Phone
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Phone Number"
                                autoComplete="organization"
                                value={values.phone}
                                onChange={handleChange("phone")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {/* {
                                error.phone && (
                                    <span className="error text-rose-700">{error.phone}</span>
                                )
                            } */}
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Merchant Key
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Key Number"
                                autoComplete="organization"
                                value={values.merchantKey}
                                onChange={handleChange("merchantKey")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {/* {
                                error.merchantKey && (
                                    <span className="error text-rose-700">{error.merchantKey}</span>
                                )
                            } */}
                        </div>
                    </div>
                    {/* <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Password
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Password"
                                autoComplete="organization"
                                value={values.password}
                                onChange={handleChange("password")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            
                        </div>
                    </div> */}
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditMerchant;