import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";

import "./App.css";
import { Sidebar, Navbar, Footer, ThemeSettings } from "./components";
import { useStateContext } from "./contexts/ContextProvider";
import { Dashboard } from "./pages";
import Merchant from "./pages/merchant/Merchant";
import CreateMerchant from "./pages/merchant/CreateMerchant";
import DetailMerchant from "./pages/merchant/DetailMerchant";
import EditMerchant from "./pages/merchant/EditMerchant";
import Courier from "./pages/courier/Courier";
import CreateCourier from "./pages/courier/CreateCourier";
import DetailCourier from "./pages/courier/DetailCourier";
import EditCourier from "./pages/courier/EditCourier";
import OrderProduct from "./pages/order/OrderProduct";
import DetailOrderProduct from "./pages/order/DetailOrderProduct";



const Home = () => {
    const {
        setCurrentColor,
        setCurrentMode,
        setThemeSettings,
        currentMode,
        activeMenu,
        themeSettings,
        currentColor,
    } = useStateContext();

    const navigate = useNavigate();

    useEffect(() => {
        const currentThemeColor = localStorage.getItem("colorMode");
        const currentThemeMode = localStorage.getItem("themeMode");
        if (currentThemeColor && currentThemeMode) {
            setCurrentColor(currentThemeColor);
            setCurrentMode(currentThemeMode);
        }
    }, []);

    useEffect(() => {
        const Id = window.localStorage.getItem("Id")
        if(!Id) {
            navigate("/login");
        }
    },[]);

    return (
        <div className={currentMode === "Dark" ? "dark" : ""}>
                <div className="flex relative dark:bg-main-dark-bg">
                    <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
                        <button
                            type="button"
                            onClick={() => setThemeSettings(true)}
                            style={{ background: currentColor, borderRadius: "50%" }}
                            className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                        >
                            <FiSettings />
                        </button>
                    </div>
                    {activeMenu ? (
                        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                            <Sidebar />
                        </div>
                    ) : (
                        <div className="w-0 dark:bg-secondary-dark-bg">
                            <Sidebar />
                        </div>
                    )}
                    <div
                        className={
                            activeMenu
                                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
                        }
                    >
                        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                            <Navbar />
                        </div>
                        <div>
                            {themeSettings && <ThemeSettings />}
                            <Routes>
                                {/* dashboard  */}
                                <Route path="dashboard" element={<Dashboard />} />
                                <Route path="merchant" element={<Merchant/>} />
                                <Route path="create_merchant" element={<CreateMerchant/>} />
                                <Route path="detail_merchant/:id" element={<DetailMerchant/>} />
                                <Route path="edit_merchant/:id" element={<EditMerchant/>} />
                                <Route path="courier" element={<Courier/>} />
                                <Route path="create_courier" element={<CreateCourier/>} />
                                <Route path="detail_courier/:id" element={<DetailCourier/>} />
                                <Route path="edit_courier/:id" element={<EditCourier/>} />
                                <Route path="order" element={<OrderProduct/>} />
                                <Route path="detail_order/:id" element={<DetailOrderProduct/>} />
                            </Routes>
                        </div>
                        {/* <Footer /> */}
                    </div>
                </div>
        </div>
    );
};

export default Home;
