import { useQuery } from "@apollo/client";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SINGLE_ORDER } from "../../gql/order";
import { useStateContext } from "../../contexts/ContextProvider";
import { IoMdArrowRoundBack } from "react-icons/io";

const DetailOrderProduct = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {currentColor} = useStateContext();

    const {data, error} = useQuery(SINGLE_ORDER, {
        variables : {id : id},
        fetchPolicy : "network-only"
    });

    console.log("single order", data)

    if(!data) {
        return "Loading...";
    }


    return (
        <div>
            <div className=" ml-10 mt-5">
                <button className=" px-3 py-2 rounded-sm"
                    onClick={() => navigate("/order")}
                    style={{ background: currentColor }}
                ><IoMdArrowRoundBack className=" text-xl text-white" />
                </button>
            </div>
            <div class="bg-white max-w-full shadow overflow-hidden sm:rounded-lg m-16">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Order
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Details and informations about order.
                    </p>
                </div>
                <div class="border-t border-gray-200">
                    {
                        <div className="grid lg:grid-cols-2 sm:grid-cols-none">
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    ID:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.id}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Order ID:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.order_id}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Merchant Name:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.merchant.name ? data.orders_by_pk.merchant.name : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Merchant Note:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.merchant_notes ? data.orders_by_pk.merchant_notes : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Courier Name:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.courier.name ? data.orders_by_pk.courier.name : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Courier Latitude:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.courier.courier_latitude ? data.orders_by_pk.courier.courier_latitude : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Courier Longitude:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.courier.courier_longitude ? data.orders_by_pk.courier.courier_longitude : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Customer Name:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.customer_name ? data.orders_by_pk.customer_name : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Customer Phone:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.customer_phone ? data.orders_by_pk.customer_phone : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Customer Note:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.customer_notes ? data.orders_by_pk.customer_notes : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Customer Address:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.customer_address ? data.orders_by_pk.customer_address : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Customer Latitude:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.customer_latitude ? data.orders_by_pk.customer_latitude : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Customer Longitude:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.customer_longitude ? data.orders_by_pk.customer_longitude : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Pick Up Name:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.pickup_name ? data.orders_by_pk.pickup_name : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Pick Up Address:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.pickup_address ? data.orders_by_pk.pickup_address : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Pick Up Latitude:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.pickup_latitude ? data.orders_by_pk.pickup_latitude : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Pick Up Longitude:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.pickup_longitude ? data.orders_by_pk.pickup_longitude : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Tracking Number:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.tracking_number ? data.orders_by_pk.tracking_number : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Order Status:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.order_status_id ? data.orders_by_pk.order_status_id : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Created At:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.created_at ? data.orders_by_pk.created_at.slice(0, 10) : "-"}
                                </div>
                            </div>
                            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <div class="text-sm font-medium text-gray-500">
                                    Updated At:
                                </div>
                                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {data.orders_by_pk.updated_at ? data.orders_by_pk.updated_at.slice(0, 10) : "-"}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default DetailOrderProduct;