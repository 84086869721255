import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { ALL_MERCHANTS } from "../../gql/merchant";
import { CREATE_COURIER } from "../../gql/courier";

const CreateCourier = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [status, setStatus] = useState("");
    const [password, setPassword] = useState("");
    const [merchantId, setMerchantId] = useState("");
    const [error, setErrors] = useState({});
    const [merchants, setMerchants] = useState([]);

    const {data, errors} = useQuery(ALL_MERCHANTS);

    useEffect(() => {
        if(data) {
            setMerchants(data.merchants);
        }
    })

    console.log("data", merchants)

    const handleChange = (value, field) => {
        const errorObject = {...error};
        if(!field) {
            errorObject[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
            } is required`;
        } else {
            errorObject[field] = "";
        }

        setErrors(errorObject);

        switch(field) {
            case "name" : 
                setName(value);
                break;
            
            case "phone" :
                setPhone(value);
                break;
            
            case "status" :
                setStatus(value);
                break;
            
            case "password" :
                setPassword(value);
                break;

            case "merchantId" :
                setMerchantId(value);
                break;
        }
    }

    const [create_courier]  = useMutation(CREATE_COURIER, {
        onError : (err) => {
            alert(err.message);
            console.log("error", err);
        },
        onCompleted : (data) => {
            setName("");
            setPassword("");
            setPhone("");
            setStatus("");
            setMerchantId("");
            alert("Courier Create Successfully");
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isError = false;
        const errorObject = {};
        setLoading(true);

        if(!name) {
            isError = true;
            errorObject.name = "Name is required";
        }

        if(!phone) {
            isError = true;
            errorObject.phone = "Phone Number is required";
        }

        if(!status) {
            isError = true;
            errorObject.status = "Status is required";
        }

        if(!password) {
            isError = true;
            errorObject.password = "Password is required";
        }

        if(!merchantId) {
            isError = true;
            errorObject.merchantId = "Merchant is required";
        }

        if(isError) {
            setErrors(errorObject);
            setLoading(false);
            return;
        }

        await create_courier({
            variables : {
                courier_status : status,
                fk_merchant_id : merchantId,
                name : name,
                password : password,
                phone : phone
            }
        })

    }


    return(
        <div className="py-10 sm:py-15">
            <div className="mx-20">
                <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">Create Courier Here</h2>
            </div>
            <form action="#" method="POST" className="mt-10 mx-20  sm:mt-15">
                <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2">
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Name"
                                autoComplete="organization"
                                value={name}
                                onChange={(e) => handleChange(e.target.value,"name")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {
                                error.name && (
                                    <span className="error text-rose-700">{error.name}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Phone
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Phone Number"
                                autoComplete="organization"
                                value={phone}
                                onChange={(e) => handleChange(e.target.value, "phone")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {
                                error.phone && (
                                    <span className="error text-rose-700">{error.phone}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Status
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Key Number"
                                autoComplete="organization"
                                value={status}
                                onChange={(e) => handleChange(e.target.value, "status")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {
                                error.status && (
                                    <span className="error text-rose-700">{error.status}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Password
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Password"
                                autoComplete="organization"
                                value={password}
                                onChange={(e) => handleChange(e.target.value, "password")}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {
                                error.password && (
                                    <span className="error text-rose-700">{error.password}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Operator</label>
                        <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={merchantId}
                        onChange={(e) => handleChange(e.target.value, "merchantId")}
                        // selected={() => handleBlur("merchantId")}
                        >
                            <option selected>Select Merchant</option>
                            {
                                Array.isArray(merchants) && merchants.map((row,index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))
                            }
                        </select>
                        {error.merchantId && <span className="error text-sm ml-1 text-red-500 mb-2">{error.merchantId}</span>}
                    </div>
                </div> 
                <div className="mt-10">
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSubmit}
                        disabled={loading}

                    >
                        {loading ? "Processing..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
};

export default CreateCourier;