import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SINGLE_COURIER, UPDATE_COURIER } from "../../gql/courier";
import { ALL_MERCHANTS } from "../../gql/merchant";

const EditCourier = () => { 
    const [values, setValues] = useState({});
    const {id} = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [status, setStatus] = useState("");
    const [merchantId, setMerchantId] = useState();

    const {data : merchant} = useQuery(ALL_MERCHANTS);

    console.log("merchant", merchant)

    const {data,error} = useQuery(SINGLE_COURIER, {
        variables : {id : id}, fetchPolicy : "network-only"
    });

    useEffect(() => {
        if(data) {
            setName(data.couriers_by_pk.name);
            setPhone(data.couriers_by_pk.phone);
            setStatus(data.couriers_by_pk.courier_status);
            setMerchantId(data.couriers_by_pk.fk_merchant_id);
        }
    },[data]);

    // const handleChange = (prop) => (event) => {
    //     setValues({...values, [prop] : event.target.value});
    // }

    const [update_courier, {loading}] = useMutation(UPDATE_COURIER,{
        onError : (err) => {
            alert("Error on server");
        },
        onCompleted : (data) => {
            alert("Courier Update Successfully");
            navigate("/courier");
        }
    });

    const handleSubmit = async(e) => {
        e.preventDefault();
        await update_courier({
            variables : {
                id : id,
                name : name,
                phone : phone,
                courier_status : status,
                fk_merchant_id : merchantId
            }
        })
    }



    return (
        <div className="py-10 sm:py-15">
            <div className="mx-20">
                <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">Edit Courier Here</h2>
            </div>
            <form action="#" method="POST" className="mt-10 mx-20  sm:mt-15">
                <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2">
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Name"
                                autoComplete="organization"
                                value={name}
                                onChange={(e) => setName(e.target.value) }
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {/* {
                                error.name && (
                                    <span className="error text-rose-700">{error.name}</span>
                                )
                            } */}
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Phone
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Phone Number"
                                autoComplete="organization"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value) }
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {/* {
                                error.phone && (
                                    <span className="error text-rose-700">{error.phone}</span>
                                )
                            } */}
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="task" className="block text-sm font-semibold leading-6 text-gray-900">
                            Status
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="task"
                                id="task"
                                placeholder="Enter Name"
                                autoComplete="organization"
                                value={status}
                                onChange={(e) => setStatus(e.target.value) }
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {/* {
                                error.name && (
                                    <span className="error text-rose-700">{error.name}</span>
                                )
                            } */}
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Operator</label>
                        <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={merchantId}
                        onChange={(e) => setMerchantId(e.target.value) }
                        // selected={() => handleBlur("merchantId")}
                        >
                            <option selected>Select Merchant</option>
                            {
                                merchant.merchants.map((row,index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditCourier;