import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_COURIERS, ONLINE_COURIER } from "../../gql/courier";

const Courier = () => {
    const navigate = useNavigate();

    const { data, error } = useQuery(ALL_COURIERS, {
        fetchPolicy: "network-only"
    });

   if(!data){
    return "Loading..."
   }

    

    return (
        <div>
            <div>
                <div className="px-20 py-8">
                    <button
                        className="bg-indigo-600 px-2 py-3 rounded text-white"
                        onClick={() => navigate("/create_courier")}
                    >
                        Add New
                    </button>
                </div>
                <div className="px-20">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className="text-center text-sm">
                                <th scope="col" className="px-6 py-3">No.</th>
                                <th scope="col" className="px-6 py-3">Name</th>
                                <th scope="col" className="px-6 py-3">Online Status</th>
                                <th scope="col" className="px-6 py-3">Phone</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {
                                data.couriers.map((row, index) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 text-center" key={index}>
                                        <td>{index + 1}</td>
                                        <td className="px-6 py-4">{row.name ? row.name : "-"}</td>
                                        <td className="px-6 py-4">{"-"}</td>
                                        <td className="px-6 py-4">{row.phone ? row.phone : "-"}</td>
                                        <td className="px-6 py-4">
                                            <button className=" bg-gray-500 px-2 py-1 text-white rounded-lg" onClick={() => navigate(`/detail_courier/${row.id}`)}>Detail</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                total_merchants = {total_merchants}
                onNextPage={handleNextPag}
                onPrevPage={handlePrevPage}
            /> */}
        </div>
    )
};

export default Courier;