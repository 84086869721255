import { gql } from "@apollo/client";

export const ORDER_PRODUCT =  gql`
query order_product ($merchantName: String!,$datefilter : timestamptz_comparison_exp!) {
  orders(where: {
  
      merchant: { name: { _ilike: $merchantName } } ,
      created_at: $datefilter
    
  }) {
    created_at
    customer_address
    customer_latitude
    customer_longitude
    customer_name
    customer_notes
    customer_phone
    fk_courier_id
    fk_merchant_id
    id
    merchant {
      name
    }
    merchant_notes
    order_id
    order_status_id
    pickup_address
    pickup_latitude
    pickup_longitude
    pickup_name
    tracking_number
    updated_at
    courier {
      courier_latitude
      courier_longitude
      courier_status
    }
  }
  orders_aggregate {
    aggregate {
      count
    }
  }
}

`;

export const SINGLE_ORDER = gql`
query single_order($id : uuid!) {
  orders_by_pk(id: $id) {
    created_at
    customer_address
    customer_latitude
    customer_longitude
    customer_name
    customer_notes
    customer_phone
    fk_courier_id
    fk_merchant_id
    id
    merchant_notes
    order_id
    order_status_id
    pickup_address
    pickup_latitude
    pickup_longitude
    pickup_name
    tracking_number
    updated_at
    courier {
      courier_latitude
      courier_longitude
      name
    }
    merchant {
      name
    }
  }
}
`;
