import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ORDER_PRODUCT } from "../../gql/order";


const OrderProduct = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    // const [searchValue, setSearchValue] = useState("");
    // const [selectedDate, setSelectedDate] = useState(null);
    // const { data, error, refetch } = useQuery(ORDER_PRODUCT, {
    //     variables: { merchantName: `%${searchValue}%` },
    //     fetchPolicy: "network-only"
    // });

    // useEffect(() => {
    //     if (data) {
    //         setOrders(data.orders);
    //     }
    // }, [data]);

    // const handleSearch = (e) => {
    //     e.preventDefault();
    //     refetch({ merchantName: `%${searchValue}%`,createdAt: selectedDate, });
    // };

    const [searchValue, setSearchValue] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);

    const startDateTask = new Date(selectedDate);
    startDateTask.setHours(0, 0, 0, 0);
    const endDateTask = new Date(startDateTask);
    endDateTask.setHours(23, 59, 59, 999);

    const { data, error, refetch } = useQuery(ORDER_PRODUCT, {
        variables: {
            merchantName: `%${searchValue}%`, datefilter: selectedDate ? { _gte: startDateTask, _lte: endDateTask } : { _is_null: false }
        },
        fetchPolicy: "network-only",
    });

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleResetFilter = () => {
        setSelectedDate(null);
    };

    return (
        <div>
            <div>
                <div className="px-20">
                    <div className="flex">
                        <form className="flex items-center mb-20 mt-5 mr-10" >
                            <label for="simple-search" class="sr-only">Search</label>
                            <div className="relative w-96 mr-3">
                                <input type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Merchant Name..." required value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)} />
                            </div>
                            <button type="submit" className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                                <span className="sr-only">Search</span>
                            </button>
                        </form>

                        

                        <div className=" mb-3">
                            <label htmlFor="date-filter" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                Filter by Created Date:
                            </label>
                            <input
                                type="date"
                                id="date-filter"
                                className=" mr-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={selectedDate || ""}
                                onChange={(e) => handleDateChange(e.target.value)}
                            />
                            <button
                                type="button"
                                onClick={handleResetFilter}
                                className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Clear Date Filter
                            </button>
                        </div>
                    </div>

                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className="text-center text-sm">
                                <th scope="col" className="px-6 py-3">No.</th>
                                <th scope="col" className="px-6 py-3">Order ID</th>
                                <th scope="col" className="px-6 py-3">Merchant Name</th>
                                <th scope="col" className="px-6 py-3">Customer Name</th>
                                <th scope="col" className="px-6 py-3">Order Status</th>
                                <th scope="col" className="px-6 py-3">Created Date</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {
                                data?.orders.map((row, index) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 text-center" key={index}>
                                        <td>{index + 1}</td>
                                        <td className="px-6 py-4">{row.order_id ? row.order_id : "-"}</td>
                                        <td className="px-6 py-4">{row.merchant.name ? row.merchant.name : "-"}</td>
                                        <td className="px-6 py-4">{row.customer_name ? row.customer_name : "-"}</td>
                                        <td className="px-6 py-4">{row.order_status_id ? row.order_status_id : "-"}</td>
                                        <td className="px-6 py-4">{row.created_at ? row.created_at.slice(0, 10) : "-"}</td>
                                        <td className="px-6 py-4">
                                            <button className=" bg-gray-500 px-2 py-1 text-white rounded-lg" onClick={() => navigate(`/detail_order/${row.id}`)}>Detail</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                total_merchants = {total_merchants}
                onNextPage={handleNextPag}
                onPrevPage={handlePrevPage}
            /> */}
        </div>
    )
};

export default OrderProduct;