import { gql } from "@apollo/client";

export const ADMIN = gql`
mutation MyMutation($password : String!, $phone : String!) {
    AdminLogin(password: $password, phone: $phone) {
      accessToken
      error
      expiredAt
      message
      type
    }
  }
`;