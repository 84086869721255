
import React, { useState } from "react";
import axios from "axios";
import { url } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import { useMutation } from "@apollo/client";
import { ADMIN } from "../../gql/admin";
import * as jose from "jose";

function Login() {
    const [values, setValues] = useState({
        phone: "",
        password: ""
    });
    const [errors, setErrors] = useState({
        phone: "",
        password: ""
    });
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ message: "", isError: false });
    const navigate = useNavigate();


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    console.log("values", values)

    const [postLogin] = useMutation(ADMIN, {
        onError: (error) => {
            alert(error.message);
            setLoading(false)
        },
        onCompleted: (result) => {
            setValues({
                phone: "",
                password: ""
            });
            const decodedToken = jose.decodeJwt(result.AdminLogin.accessToken);

            if (decodedToken.exp * 1000 < Date.now()) {
                navigate("/login");
            }
            // console.log("id", decodedToken);

            const data = JSON.stringify({
                token: result.AdminLogin.accessToken,
                Id: decodedToken.sub
            });

            window.localStorage.setItem("loggedUser", data);
            window.localStorage.setItem("Id", decodedToken.sub);
            alert("Login Successfully!")
            console.log("token", decodedToken)
            navigate("/dashboard");
        }
    })

    const handleLogin = async (e) => {
        e.preventDefault();
        const errorObject = {};

        setLoading(true);

        if (!values.phone) {
            errorObject.phone = "User Phone Number is Required!";
        }

        if (!values.password) {
            errorObject.password = "Password is Required!";
        }

        if (Object.keys(errorObject).length > 0) {
            setErrors(errorObject);
            return;
        }

        setErrors({});

        try {
            const result = await postLogin({
                variables: {
                    phone: values.phone,
                    password: values.password
                }
            });
            console.log("result", result)
        } catch (error) {
            alert("Error");
            console.log("error", error);
        }
    }



    return (
        <section className="bg-slate-500 dark:bg-slate-800">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    {/* <img class="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" /> */}
                    Main Dashboard
                </a>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Sign in to your account
                        </h1>
                        <form className="space-y-4 md:space-y-6" action="#">
                            <div>
                                <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Phone Number</label>
                                <input type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="phone number" required=""
                                    value={values.phone}
                                    onChange={handleChange("phone")}
                                />
                                {errors.phone && <span className="error text-red-500 mb-2">{errors.phone}</span>}
                            </div>
                            <div>
                                <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                    value={values.password}
                                    onChange={handleChange("password")}
                                />
                                {errors.password && <span className="error text-red-500 mb-2">{errors.password}</span>}
                            </div>
                            <button type="submit" onClick={handleLogin} className="w-full text-white bg-cyan-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" disabled={loading}>{loading ? "loading..." : "Sign In"}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;









