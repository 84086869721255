import { gql } from "@apollo/client";

export const CREATE_MERCHANT = gql`
mutation create_merchant($email : String!, $name : String!, $password : String!, $phone : String!) {
  MerchantRegister(email: $email, name: $name, password: $password, phone: $phone) {
    accessToken
    error
    expiredAt
    message
    type
  }
}
`;

export const ALL_MERCHANTS = gql`
  query MyQuery {
    merchants(order_by: { created_at: desc }) {
      created_at
      email
      id
      image_url
      merchant_key
      name
      otp
      phone
      updated_at
      password
    }
    merchants_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const SINGLE_MERCHANT = gql`
  query MyQuery($id: uuid!) {
    merchants_by_pk(id: $id) {
      created_at
      email
      id
      image_url
      merchant_key
      name
      otp
      password
      phone
      updated_at
    }
  }
`;

export const UPDATE_MERCHANT = gql`
mutation update_merchant($id : uuid!, $merchant_key : String!, $name : String!, $phone : String!) {
    update_merchants_by_pk(
    pk_columns: {id: $id}, 
    _set: {
        merchant_key: $merchant_key,
        name: $name,
        phone: $phone,
    }
    ) {
      id
      email
      created_at
      image_url
      merchant_key
      name
      otp
      password
      phone
      updated_at
    }
  }
`;

export const DELETE_MERCHANT = gql`
mutation MyMutation($id : uuid!) {
    delete_merchants(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;