import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUI } from "../../components/UIContext";
import { RiDeleteBin2Fill } from "react-icons/ri";
import Pagination from "../../components/Pagination";
import { ALL_MERCHANTS } from "../../gql/merchant";

const Merchant = () => {
    const navigate = useNavigate();
    const [merchants, setMerchants] = useState();
    const { setDeleteAction, deleteAction } = useUI();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;


    const [loadMerchant, resultMerchant] = useLazyQuery(ALL_MERCHANTS);

    useEffect(() => {
        loadMerchant({fetchPolicy : "network-only"});
    }, [loadMerchant]);

    useEffect(() => {
        if(resultMerchant.data) {
            setMerchants(resultMerchant.data.merchants);
        }
    },[resultMerchant]);

    console.log("first", merchants)

    const totalPages = Math.ceil(resultMerchant.data?.merchants_aggregate.aggregate.count/itemsPerPage);

    const total_merchants = Math.ceil(resultMerchant.data?.merchants_aggregate.aggregate.count);
    
    const currentData = merchants?.slice(
        (currentPage - 1) * itemsPerPage, currentPage * itemsPerPage
    );

    const handleNextPag = () => {
        setCurrentPage((prevPag) => prevPag + 1);
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPag) => prevPag -1);
    }

    return (
        <div>
            <div>
                <div className="px-20 py-8">
                    <button
                        className="bg-indigo-600 px-2 py-3 rounded text-white"
                        onClick={() => navigate("/create_merchant")}
                    >
                        Add New
                    </button>
                </div>
                <div className="px-20">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className="text-center text-sm">
                                <th scope="col" className="px-6 py-3">No.</th>
                                <th scope="col" className="px-6 py-3">Name</th>
                                <th scope="col" className="px-6 py-3">Email</th>
                                <th scope="col" className="px-6 py-3">Phone</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {
                            Array.isArray(currentData) && currentData.map((row, index) => (
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 text-center" key={index}>
                                    <td className="px-6 py-4">{index + 1}</td>
                                    <td className="px-6 py-4">{row.name}</td>
                                    <td className="px-6 py-4">{row.email}</td>
                                    <td className="px-6 py-4">{row.phone}</td>
                                    <td className="px-6 py-4">
                                        <button className=" bg-gray-500 px-2 py-1 text-white rounded-lg" onClick={() => navigate(`/detail_merchant/${row.id}`)}>Detail</button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                total_merchants = {total_merchants}
                onNextPage={handleNextPag}
                onPrevPage={handlePrevPage}
            /> */}
        </div>
    );
};

export default Merchant;
